<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <!-- <div
      class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
            <v-col cols="12">
              <h2 class="heading-title1 text-h2 text-left font-weight-bold" style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                PSrE-hub
              </h2>
            </v-col>
            <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">External data hub centralized electronic signature multiple PSrE.</h5>
            </v-col>
        </v-row>
      </v-container>

      <div class="video-background">
        <span>
          <video muted="" autoplay="" playsinline="" loop="" poster="">
            <source src="../assets/images/bg/psre-vid.mp4" />
          </video>
        </span>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">PSrE-hub</h4>
              <h3 class="heading-title mb-5">
                External data hub<br>centralized electronic signature multiple PSrE.
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center;" class="my-8">PSrE-hub is a pioneering system that allows users to process digital signatures for<br>various type of documents safely and seamlessly which can be integrated with various Certificate Authorities.</p>
            </div>
            <ServicePsre/>
          </div>
        </v-container>
      </div>
    </div>  
    <!-- End Content -->

    <!-- Start feature Area  -->
    <!-- <div class="rn-team-area rn-section-gapsiber bg-feature">
      <v-container style="margin-top: 20px; margin-bottom: 20px">
        <FeatureArea/>
      </v-container>
    </div> -->
    <!-- End feature Area  -->

    <!-- Start Content -->
    <!-- <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px" id="blog">
      <div class="contact-form--1">
        <v-container class="pa-0 d-flex justify-center" style="margin: auto;">
          <div class="section rn-blog-area rn-section-gap" > 
            <div class="section-title section-title--2 mb--20">
              <v-row>
                <v-col cols="6">
                  <v-img contain width="1700" height="auto" src="../assets/images/featured/marine-monitor.png"/>
                </v-col>
                <v-col cols="6" class="d-flex align-start pt-16">
                  <div class="d-flex flex-column justify-start" style="margin-bottom: 80px;">
                    <img src="../assets/images/icons/people says.svg" style="position: absolute; z-index:1; width: auto; height: 150px; left:-3px; top:8px" >
                    <div class="section-title section-title--2 text-justify mb--20" style="position: absolute; z-index:2;">
                      <h4 class="heading-title" style="line-height: 44.8px !important;">"Marine surveillance is a web-based application that integrates with multiple data source and compile these datas to a visualized dashboard. With these data, not only marine survaillace is able to visualize the datas provided but also to generate other information related to the users interest. These includes Alerts, Analytics, Regional Density, and so much more."</h4>
                      <v-avatar class="mr-3">
                        <img
                          src="../assets/images/blog/radit.png"
                          alt="Developer"
                        >
                      </v-avatar>
                      <span
                        class="grey--text"
                        style="font-size: 18px"
                      >
                        Raditya Eko Prabowo, Developer.
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-container>
      </div>
    </div>   -->
    <!-- End Content -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
  import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
  import Contact from "../components/contact/Contact";
  import FeatureArea from "../components/brand/BrandFeatureAirTraffic.vue";
  import ServicePsre from "../components/service/ServicePsre.vue";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderOnePageTwo,
      HeaderOnePageTwoMobile,
      Contact,
      FeatureArea,
      ServicePsre,
      Footer,
    },
    data() {
      return {
        features: [
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/monitor.svg"),
            titleFeatures: "Map Based Flight Monitoring",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/info.svg"),
            titleFeatures: "Flight and Airport Information Panel",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/filter.svg"),
            titleFeatures: "Flight Search & Filtering",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/database.svg"),
            titleFeatures: "Real-time Data Source Processing",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/play.svg"),
            titleFeatures: "Playback Flights",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/bell.svg"),
            titleFeatures: "Custom Alert & Notification",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/pie-chart.svg"),
            titleFeatures: "Analytics & Reporting",
          },
        ]
      };
    },
  };
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-title2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-subtitle2 {
    color: #dd8732 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-caption {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}
.heading-caption2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}
.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;
    
}
.bg-feature {
  background-image: linear-gradient(45deg, #232526, #414345);
}
</style>