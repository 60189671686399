<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <!-- <div
      class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
            <v-col cols="12">
                <h2 class="heading-title1 text-left font-weight-bold" style="font-size: 60px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                    Observability
                </h2>
            </v-col>
            <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">Centralized Log Management Observability and Analytics Dashboard.</h5>
            </v-col>
        </v-row>
        <div>

        </div>
      </v-container>

      <div class="photo-background">
        <span>
          <img src="../assets/images/bg/business-5475664.png" alt="" style="background-repeat: no-repeat; background-size: cover; background-position: center center;"/>
        </span>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Securepipe</h4>
              <h3 class="heading-title mb-5">
                Centralized Log Management
              </h3>
              <!-- <h4 class="heading-title">Observability and Analytics Dashboard</h4>
              <h3 class="heading-title mb-5">
                Application Performance Monitoring
              </h3> -->
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <!-- <p style="text-align: center;" class="my-8">Application Performance Monitoring.</p> -->
            </div>
            <v-row class="service-one-wrapper" style="margin: 80px 0 80px 0">
              <v-col cols="6">
                <div style="margin: auto; display: flex; width: auto">
                  <v-img contain height="auto" src="../assets/images/featured/visibilityhassle.png"/>
                </div>
              </v-col>
              <v-col cols="6" class="d-flex justify-center align-center">
                <div class="section-title section-title--2">
                  <!-- <h4 class="heading-title">Log Monitoring</h4> -->
                  <h3 class="heading-title mb-5">
                    Closing The Visibility &<br>The hassle
                  </h3>
                  <p class="mt-5">The Centralized Log Management help you to extend the source of information for analytics matter such as security correlation and information gathering to the fingertips.</p>
                  <p class="mt-2">Scalable system to bring source of intelligence by collecting numerous amount of logs from various sources for in-depth visibility around the environment.</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </div>  
    <!-- End Content -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h3 class="heading-title mb-5">
                Observability and Analytics Dashboard for Application Performance Monitoring
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <!-- <p style="text-align: center;" class="my-8">Application Performance Monitoring.</p> -->
            </div>
            <v-row class="service-one-wrapper" style="margin: 80px 0 80px 0">
              <v-col cols="6" class="d-flex justify-center align-center">
                <div class="section-title section-title--2">
                  <!-- <h4 class="heading-title">Log Monitoring</h4> -->
                  <h3 class="heading-title mb-5">
                    Monitor Application & Server Performance with a single dynamic dashboard
                  </h3>
                  <p class="mt-5">APM feature contains:</p>
                  <ul class="mt-2">
                    <li>Application monitoring</li>
                    <li>Microservices monitoring</li>
                    <li>Network monitoring</li>
                    <li>Infrastructure monitoring</li>
                    <li>Database monitoring</li>
                  </ul>
                </div>
              </v-col>
              <v-col cols="6">
                <div style="margin: auto; display: flex; width: auto">
                  <v-img contain height="auto" src="../assets/images/featured/monitorapplication.png"/>
                </div>
              </v-col>
            </v-row>
            <!-- <div style="padding: 0px 0px 0px px"> -->
              <v-row style="justify-content: center !important; max-heigth: 50vh; min-height: 50vh">
                <v-col xl="12" lg="12" md="12" sm="12" cols="12" class="mx-xl-12 mx-lg-12 mx-md-12 mx-sm-auto mx-xs-auto d-flex justify-center align-center">
                  <v-slide-y-reverse-transition>
                    <div v-if="showContent">
                      <div>
                        <img :src="serviceWhite[activeService].img"
                          style="border-radius: 0%; width: 60vw; margin-top:20px; margin-bottom: 30px;" />
                      </div>
                    </div>
                  </v-slide-y-reverse-transition>
                </v-col>
              </v-row>
              <v-row style="justify-content: center !important; ">
                <v-col xl="12" lg="12" md="12" sm="12" cols="12" class="mx-xl-12 mx-lg-12 mx-md-12 mx-sm-auto mx-xs-auto d-flex justify-center align-center">
                  <v-slide-y-reverse-transition>
                    <div v-if="showContent">
                      <div>
                        <div class="content">
                          <div>
                            <h3 class="title text-center" style="color:darkgray; padding-bottom: 10px;">{{
                              serviceWhite[activeService].title }}</h3>
                          </div>
                          <p class="text-center"
                            style="font-size: 14px; box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 0.5); background-color: white; padding: 10px;">
                            {{ serviceWhite[activeService].description }}</p>
                        </div>
                      </div>
                    </div>
                  </v-slide-y-reverse-transition>
                </v-col>
              </v-row>
              <v-row style="margin-bottom: 20px;">
                <v-bottom-navigation shift style="background-color: rgba(0,0,0,0); box-shadow: none;" v-model="activeService">
                  <v-btn v-bind:key="index" v-for="(item, index) in serviceWhite">
                    <!-- <img :src="item.img" style="border-radius: 0%;" /> -->
                    <v-icon>mdi-circle-medium</v-icon>
                  </v-btn>
                </v-bottom-navigation>
              </v-row>
            <!-- </div> -->
          </div>
        </v-container>
      </div>
    </div>  
    <!-- End Content -->

    <!-- <Footer /> -->
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
  import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
  import Contact from "../components/contact/Contact";
  // import Observability4APM from "../components/blog/Observability4APM.vue";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderOnePageTwo,
      HeaderOnePageTwoMobile,
      Contact,
      // Observability4APM,
      Footer,
    },
    data() {
      return {
        showContent: true,
        activeService: 0,
        selectedService: [
          {
            id: "monitoring-application",
            dark: false,
          },
          {
            id: "improve_code",
            dark: false,
          },
          {
            id: "quickly-identify",
            dark: false,
          },
          {
            id: "simply-monitor",
            dark: false,
          },
        ],
        selectedServiceIndex: "",
        serviceWhite: [
          {
            img: require("../assets/images/featured/monitoring-application.png"),
            title: 'Monitoring Application services status',
            description: 'Show the healthy status of the current running application with traffic statistic such as latency and throughput. This also cover the success rate of the application transaction.',
            linkDetail: '/'
          },
          {
            img: require("../assets/images/featured/improve_code.png"),
            title: 'Improve code quality and trace error with end-to-end distributed tracing',
            description: 'Tracing end to end application transaction with in depth visibility from parent transaction thoroughly to the backend transaction processes with highlighted error status to conduct deep dive tracing.',
            linkDetail: '/'
          },
          {
            img: require("../assets/images/featured/quickly-identify.png"),
            title: 'Quickly identify application problems with service mapping',
            description: 'Identify detailed application and database services by mapping the topology and detect anomaly with fundamental transaction information (Latency, Throughput, Transaction Rate).',
            linkDetail: '/'
          },
          {
            img: require("../assets/images/featured/simply-monitor.png"),
            title: 'Simply monitor application uptime',
            description: 'Monitors the application uptime by sending pings over time to check the availability of applications. It also provides details about what time the application is available and can send alerts when the application is down.',
            linkDetail: '/'
          },
        ],
      };
    },
    watch: {
      activeService(e, v) {
        // console.log(e)
        this.showContent = false
        this.$nextTick(() => {
          this.showContent = true
        })
      },
    }
  };
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-title2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-subtitle2 {
    color: #dd8732 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-caption {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}
.heading-caption2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}
.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;
}
</style>